import React from 'react';
import history from '../../history';
import styled from 'styled-components';
import {Container, Col, Row} from 'react-bootstrap';
import Button from '@material-ui/core/Button';

import './Home.css';
import SwitchTransition from "react-transition-group/SwitchTransition";
import {CSSTransition} from "react-transition-group";
import Loading from "../Loading/Loading";
import MediaQuery from "react-responsive";
import Slide from "@material-ui/core/Slide";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from "@material-ui/core/Snackbar";

const Styles = styled.div`
    .MuiButton-label{
        font-family: "Open Sans",sans-serif !important;

    }
    
`;

class Home extends React.Component {

    state = {
        isLoading: true,
        mobile: false,
    };

    componentDidMount() {
        window.setTimeout(() => this.setState({isLoading: false}), 1000);
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
        window.setTimeout(() => this.setState({mobile: false}), 5000);
    }

    resize() {
        this.setState({mobile: window.innerWidth <= 425});
    }

    handleClick = () => {
        history.push('/contact')
    };

    SlideTransition = (props) => {
        return <Slide {...props} direction="up"/>
    };

    handleSnackBarClose = () => {
        this.setState({mobile: false})
    };

    renderContent = () => {
        switch (this.state.isLoading) {
            case true:
                return <Loading/>;
            default:
                return (
                    <Container fluid style={{height: '100vh', overflow: 'hidden'}}
                               className='d-flex justify-content-center align-items-center'>
                        <Styles style={{width: '100%', height: '100%'}}>
                            <Row className='h-100'>
                                <Col xs={{span: 10, offset: 1}} sm={{span: 5, offset: 1}}
                                     className='d-flex align-content-center justify-content-center flex-column'>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1022.64 539.12" id='mainText'>
                                        <g id="Layer_2" data-name="Layer 2">
                                            <g id="Layer_1-2" data-name="Layer 1">
                                                <path className="cls-1"
                                                      d="M61.16,96.64V54.45H24.5V96.64H4.82v-94H24.5V38H61.16V2.63H81v94Z"/>
                                                <path className="cls-1"
                                                      d="M90.75,18.63V1.5h18.4V18.63Zm0,78V27h18.4V96.64Z"/>
                                                <path className="cls-1" d="M4.82,228.64v-94H24.5v94Z"/>
                                                <path className="cls-1"
                                                      d="M33.41,164.93a14.63,14.63,0,0,0,8.07-4.81q2.7-3.41,2.84-10.2H33.41V130.81H53v14.44a49.5,49.5,0,0,1-1,10.48,20.78,20.78,0,0,1-3.26,7.71,18.15,18.15,0,0,1-6,5.31A31.75,31.75,0,0,1,33.41,172Z"/>
                                                <path className="cls-1"
                                                      d="M144.69,228.64V186.45q0-6.37-3-10.34a9.41,9.41,0,0,0-7.92-4,10.26,10.26,0,0,0-8.43,4.25A16.72,16.72,0,0,0,122,187v41.63H103.63V186.73a35.45,35.45,0,0,0-.56-6.94,9.32,9.32,0,0,0-1.92-4.24,7.21,7.21,0,0,0-3.46-2.2,18.1,18.1,0,0,0-5.1-.64q-11.19,0-11.18,13.6v42.33H63V159H80.27v10.2q3.54-6.94,8.5-9.49a25.67,25.67,0,0,1,11.89-2.55,31.34,31.34,0,0,1,6.3.57,15.2,15.2,0,0,1,5,1.91,19.91,19.91,0,0,1,4.18,3.47,39.57,39.57,0,0,1,4,5.09,18.66,18.66,0,0,1,7.51-8.42,22.88,22.88,0,0,1,11.32-2.62,25.55,25.55,0,0,1,9.63,1.77,21.6,21.6,0,0,1,7.5,5,23.44,23.44,0,0,1,6.66,16.49v48.28Z"/>
                                                <path className="cls-1"
                                                      d="M244.92,134.63H264.6v68.66q0,12.89-7.79,20.75t-23.07,7.85q-13.74,0-21.38-7.29t-7.65-21.87V193.1h17.84v8.64q0,7.22,2.55,10.69t8.64,3.46q6.8,0,9-3.32t2.19-11.12Z"/>
                                                <path className="cls-1"
                                                      d="M319.38,228.64v-9.77q-7.08,11.61-21.66,11.61a24.23,24.23,0,0,1-9.55-1.84,21.3,21.3,0,0,1-7.43-5.24,24.85,24.85,0,0,1-4.89-8.07,29.42,29.42,0,0,1-1.77-10.48V159h18.41v43q0,13,11.75,13,7.08,0,10.69-4.45a17.6,17.6,0,0,0,3.61-11.4V159H336.8v69.66Z"/>
                                                <path className="cls-1"
                                                      d="M388.75,180.08c-.47-3.4-1.69-5.78-3.68-7.15s-5.09-2.05-9.34-2.05a19.29,19.29,0,0,0-8.07,1.34,4.59,4.59,0,0,0-2.83,4.46,5.16,5.16,0,0,0,2.83,4.74,38,38,0,0,0,8.5,3.19q5.94,1.69,11.32,3a87.83,87.83,0,0,1,9.49,2.9,19.88,19.88,0,0,1,8.49,6.16q3,3.9,3,10.69,0,10.9-7.71,17T378,230.48q-16.14,0-24.71-6.8a22.06,22.06,0,0,1-8.85-17.41h19a8.79,8.79,0,0,0,3.83,7.64q3.81,2.7,10.61,2.69a21.14,21.14,0,0,0,8.64-1.63,5.68,5.68,0,0,0,3.68-5.59,5.38,5.38,0,0,0-3.33-5.24,44.24,44.24,0,0,0-7.85-2.69c-5.86-1.41-10.57-2.69-14.16-3.82a36.72,36.72,0,0,1-9.06-4.1,17.81,17.81,0,0,1-6.87-7.08,19.67,19.67,0,0,1-1.91-8.64,21.54,21.54,0,0,1,1.56-8.21,15.9,15.9,0,0,1,5.1-6.58,26,26,0,0,1,9.13-4.32,50.8,50.8,0,0,1,13.52-1.56q15.13,0,22.29,5.81t7.86,17.13Z"/>
                                                <path className="cls-1"
                                                      d="M454.3,182.77h17.13v19.82a33.79,33.79,0,0,1-1.63,10.54,24,24,0,0,1-13.87,15.08,32.2,32.2,0,0,1-12.67,2.27,33.55,33.55,0,0,1-13-2.27,24.76,24.76,0,0,1-8.85-6.09,23.13,23.13,0,0,1-5-8.92,36.49,36.49,0,0,1-1.56-10.75v-62.3h18.12V159h38.51v13H432.92V200.6q0,6.66,2.48,10.27t8.14,3.61q5.52,0,8.14-3.54t2.62-10.05Z"/>
                                                <path className="cls-1"
                                                      d="M526.22,228.64v-9.77q-7.08,11.61-21.66,11.61a24.25,24.25,0,0,1-9.56-1.84,21.3,21.3,0,0,1-7.43-5.24,24.85,24.85,0,0,1-4.89-8.07,29.42,29.42,0,0,1-1.77-10.48V159h18.41v43q0,13,11.75,13,7.08,0,10.69-4.45a17.6,17.6,0,0,0,3.61-11.4V159h18.26v69.66Z"/>
                                                <path className="cls-1"
                                                      d="M595.59,180.08c-.48-3.4-1.7-5.78-3.68-7.15s-5.1-2.05-9.35-2.05a19.29,19.29,0,0,0-8.07,1.34,4.59,4.59,0,0,0-2.83,4.46,5.16,5.16,0,0,0,2.83,4.74,38,38,0,0,0,8.5,3.19q5.94,1.69,11.32,3a87.83,87.83,0,0,1,9.49,2.9,19.88,19.88,0,0,1,8.49,6.16q3,3.9,3,10.69,0,10.9-7.71,17t-22.72,6.09q-16.14,0-24.71-6.8a22.06,22.06,0,0,1-8.85-17.41h19a8.79,8.79,0,0,0,3.83,7.64q3.82,2.7,10.61,2.69a21.11,21.11,0,0,0,8.64-1.63,5.68,5.68,0,0,0,3.68-5.59,5.38,5.38,0,0,0-3.33-5.24,44.24,44.24,0,0,0-7.85-2.69c-5.86-1.41-10.57-2.69-14.16-3.82a36.72,36.72,0,0,1-9.06-4.1,17.81,17.81,0,0,1-6.87-7.08,19.67,19.67,0,0,1-1.91-8.64,21.54,21.54,0,0,1,1.56-8.21,15.9,15.9,0,0,1,5.1-6.58,26,26,0,0,1,9.13-4.32,50.86,50.86,0,0,1,13.52-1.56q15.15,0,22.29,5.81t7.86,17.13Z"/>
                                                <path className="cls-1"
                                                      d="M622.06,243.64a14.71,14.71,0,0,0,8.07-4.81q2.68-3.41,2.83-10.19h-10.9V209.52h19.53V224a49.43,49.43,0,0,1-1,10.48,20.89,20.89,0,0,1-3.25,7.72,18.27,18.27,0,0,1-6,5.3,31.78,31.78,0,0,1-9.27,3.26Z"/>
                                                <path className="cls-1"
                                                      d="M53.52,360.64l-.14-8.5Q47,362.48,34,362.48a28.65,28.65,0,0,1-12.17-2.55,26.61,26.61,0,0,1-9.49-7.36,35.8,35.8,0,0,1-6.23-11.75A50.6,50.6,0,0,1,3.83,325.1a51.73,51.73,0,0,1,1.91-14.51,34.1,34.1,0,0,1,5.59-11.4,25.36,25.36,0,0,1,9-7.43,27.07,27.07,0,0,1,12.1-2.62q12.88,0,20.67,10.9V265.5H71.21v95.14ZM38.23,347.47A13.74,13.74,0,0,0,49.34,342q4.45-5.52,4.46-14.44,0-22.65-15.43-22.65-15.85,0-15.86,21.52,0,9.33,4.39,15.21A13.54,13.54,0,0,0,38.23,347.47Z"/>
                                                <path className="cls-1"
                                                      d="M124.87,360.64a27.63,27.63,0,0,1-1.13-6.23,22.83,22.83,0,0,1-8.64,5.87,32.88,32.88,0,0,1-12.46,2.2q-12,0-17.62-5.31a18.17,18.17,0,0,1-5.6-13.81q0-7.5,2.41-12a18,18,0,0,1,6.66-7,32.15,32.15,0,0,1,10.12-3.75q5.86-1.2,12.81-2.19c4.62-.66,7.74-1.53,9.34-2.62s2.41-2.86,2.41-5.31a5.91,5.91,0,0,0-3.19-5.31,16.58,16.58,0,0,0-8.56-1.91q-6.37,0-9.13,2.83a13.27,13.27,0,0,0-3.47,7.5H81.69a31.43,31.43,0,0,1,1.77-9.91,19,19,0,0,1,5.24-7.78,24.45,24.45,0,0,1,9.41-5,49.07,49.07,0,0,1,14.16-1.77A46.22,46.22,0,0,1,126.21,291a22.55,22.55,0,0,1,9.21,5.31,20.28,20.28,0,0,1,5,8.64A40.37,40.37,0,0,1,142,316.61v44Zm-1.28-34.12a9.89,9.89,0,0,1-4.1,2.26,74,74,0,0,1-7.79,1.84q-7.92,1.56-10.9,4a8.39,8.39,0,0,0-3,6.93q0,7.8,8.92,7.79a17.77,17.77,0,0,0,6.58-1.2,16.08,16.08,0,0,0,8.78-8.07,13.33,13.33,0,0,0,1.34-5.88Z"/>
                                                <path className="cls-1"
                                                      d="M191.55,314.77h17.13v19.82a34.08,34.08,0,0,1-1.63,10.54,24.69,24.69,0,0,1-5.1,8.92,24.31,24.31,0,0,1-8.77,6.16,32.2,32.2,0,0,1-12.67,2.27,33.55,33.55,0,0,1-13-2.27,24.58,24.58,0,0,1-8.85-6.09,23,23,0,0,1-5-8.92,36.49,36.49,0,0,1-1.56-10.75v-62.3h18.12V291h38.51v13H170.17V332.6q0,6.66,2.48,10.27t8.14,3.61q5.52,0,8.14-3.54t2.62-10.05Z"/>
                                                <path className="cls-1"
                                                      d="M261.77,360.64a28.17,28.17,0,0,1-1.14-6.23,22.79,22.79,0,0,1-8.63,5.87,32.88,32.88,0,0,1-12.46,2.2q-12,0-17.63-5.31t-5.59-13.81q0-7.5,2.41-12a18,18,0,0,1,6.65-7,32.24,32.24,0,0,1,10.12-3.75q5.88-1.2,12.82-2.19t9.34-2.62c1.6-1.08,2.41-2.86,2.41-5.31a5.93,5.93,0,0,0-3.19-5.31,16.6,16.6,0,0,0-8.56-1.91q-6.37,0-9.14,2.83a13.26,13.26,0,0,0-3.46,7.5H218.59a31.43,31.43,0,0,1,1.77-9.91,19,19,0,0,1,5.23-7.78,24.56,24.56,0,0,1,9.42-5,49.07,49.07,0,0,1,14.16-1.77A46.22,46.22,0,0,1,263.11,291a22.51,22.51,0,0,1,9.2,5.31,20.3,20.3,0,0,1,5,8.64,40.37,40.37,0,0,1,1.56,11.68v44Zm-1.28-34.12a9.89,9.89,0,0,1-4.1,2.26,74.61,74.61,0,0,1-7.79,1.84q-7.93,1.56-10.9,4a8.39,8.39,0,0,0-3,6.93q0,7.8,8.91,7.79a17.83,17.83,0,0,0,6.59-1.2,16.42,16.42,0,0,0,5.24-3.26A16.22,16.22,0,0,0,259,340a13.21,13.21,0,0,0,1.35-5.88Z"/>
                                                <path className="cls-1"
                                                      d="M368.65,360.64a27.63,27.63,0,0,1-1.13-6.23,22.83,22.83,0,0,1-8.64,5.87,32.88,32.88,0,0,1-12.46,2.2q-12,0-17.62-5.31t-5.59-13.81q0-7.5,2.4-12a18,18,0,0,1,6.66-7,32.15,32.15,0,0,1,10.12-3.75q5.87-1.2,12.81-2.19c4.62-.66,7.74-1.53,9.34-2.62S367,313,367,310.52a5.91,5.91,0,0,0-3.19-5.31,16.58,16.58,0,0,0-8.56-1.91q-6.37,0-9.13,2.83a13.27,13.27,0,0,0-3.47,7.5H325.47a31.43,31.43,0,0,1,1.77-9.91,19,19,0,0,1,5.24-7.78,24.45,24.45,0,0,1,9.41-5,49.07,49.07,0,0,1,14.16-1.77A46.33,46.33,0,0,1,370,291a22.57,22.57,0,0,1,9.2,5.31,20.28,20.28,0,0,1,5,8.64,40.37,40.37,0,0,1,1.56,11.68v44Zm-1.27-34.12a10,10,0,0,1-4.11,2.26,74,74,0,0,1-7.79,1.84q-7.92,1.56-10.9,4a8.39,8.39,0,0,0-3,6.93q0,7.8,8.92,7.79a17.77,17.77,0,0,0,6.58-1.2,16.08,16.08,0,0,0,8.78-8.07,13.33,13.33,0,0,0,1.34-5.88Z"/>
                                                <path className="cls-1"
                                                      d="M440.42,360.64V318.45q0-8.07-2.68-10.9t-9.21-2.84q-14.29,0-14.3,16v39.93h-18.4V291h17.55v10.2a22.31,22.31,0,0,1,8.64-9q5.39-3,13.87-3.05a27.71,27.71,0,0,1,9.21,1.49,19.52,19.52,0,0,1,7.29,4.46,21.31,21.31,0,0,1,4.81,7.29,26.18,26.18,0,0,1,1.77,10v48.28Z"/>
                                                <path className="cls-1"
                                                      d="M512.62,360.64a28.79,28.79,0,0,1-1.13-6.23,22.79,22.79,0,0,1-8.63,5.87,32.88,32.88,0,0,1-12.46,2.2q-12,0-17.63-5.31t-5.59-13.81q0-7.5,2.41-12a18,18,0,0,1,6.65-7,32.24,32.24,0,0,1,10.12-3.75q5.88-1.2,12.81-2.19c4.63-.66,7.74-1.53,9.35-2.62s2.41-2.86,2.41-5.31a5.93,5.93,0,0,0-3.19-5.31,16.62,16.62,0,0,0-8.57-1.91c-4.24,0-7.29.94-9.13,2.83a13.26,13.26,0,0,0-3.46,7.5H469.45a31.71,31.71,0,0,1,1.76-9.91,19.14,19.14,0,0,1,5.24-7.78,24.56,24.56,0,0,1,9.42-5A49,49,0,0,1,500,289.14,46.24,46.24,0,0,1,514,291a22.51,22.51,0,0,1,9.2,5.31,20.3,20.3,0,0,1,5,8.64,40.73,40.73,0,0,1,1.55,11.68v44Zm-1.27-34.12a9.89,9.89,0,0,1-4.1,2.26,74.61,74.61,0,0,1-7.79,1.84q-7.93,1.56-10.9,4a8.4,8.4,0,0,0-3,6.93q0,7.8,8.92,7.79a17.83,17.83,0,0,0,6.59-1.2,16.26,16.26,0,0,0,5.23-3.26,16.08,16.08,0,0,0,3.54-4.81,13.21,13.21,0,0,0,1.35-5.88Z"/>
                                                <path className="cls-1" d="M539.8,360.64V265.5h18.41v95.14Z"/>
                                                <path className="cls-1"
                                                      d="M612.29,351.43a14.46,14.46,0,0,1-7.15,7.29,25.44,25.44,0,0,1-11.68,2.62,28.51,28.51,0,0,1-10.19-1.77,22.92,22.92,0,0,1-8.07-5.09,23.53,23.53,0,0,1-5.31-7.93A27,27,0,0,1,568,336.14V291h18.4v42.47q0,13,11.75,13a14.36,14.36,0,0,0,10.27-3.68,12.34,12.34,0,0,0,3.89-9.35V291h18.4v71.5a33.28,33.28,0,0,1-2.47,13.16,28,28,0,0,1-6.8,9.77,30.1,30.1,0,0,1-10.19,6.09,36.76,36.76,0,0,1-12.53,2.12q-15.44,0-23.08-7.79T568,364.46h17.13q0,6.23,3.61,9.77c2.41,2.35,6,3.54,10.83,3.54a11.16,11.16,0,0,0,4.81-1.07,13,13,0,0,0,4-3,14.2,14.2,0,0,0,2.83-4.74,17.6,17.6,0,0,0,1.06-6.23Z"/>
                                                <path className="cls-1"
                                                      d="M682.93,312.08q-.7-5.1-3.68-7.15t-9.34-2.05a19.29,19.29,0,0,0-8.07,1.34,4.59,4.59,0,0,0-2.83,4.46,5.16,5.16,0,0,0,2.83,4.74,38.06,38.06,0,0,0,8.49,3.19q6,1.69,11.33,3a87.61,87.61,0,0,1,9.48,2.9,19.92,19.92,0,0,1,8.5,6.16q3,3.9,3,10.69,0,10.91-7.71,17t-22.73,6.09q-16.14,0-24.7-6.8a22.06,22.06,0,0,1-8.85-17.41h19a8.8,8.8,0,0,0,3.82,7.64q3.83,2.7,10.62,2.69a21.18,21.18,0,0,0,8.64-1.63,5.68,5.68,0,0,0,3.68-5.59,5.38,5.38,0,0,0-3.33-5.24,44.65,44.65,0,0,0-7.86-2.69q-8.77-2.11-14.15-3.82a36.72,36.72,0,0,1-9.06-4.1,17.74,17.74,0,0,1-6.87-7.08,19.67,19.67,0,0,1-1.91-8.64,21.54,21.54,0,0,1,1.56-8.21,15.79,15.79,0,0,1,5.09-6.58A26.11,26.11,0,0,1,657,290.7a50.91,50.91,0,0,1,13.52-1.56q15.15,0,22.3,5.81t7.86,17.13Z"/>
                                                <path className="cls-1"
                                                      d="M748.48,314.77h17.13v19.82A34.08,34.08,0,0,1,764,345.13a24.54,24.54,0,0,1-5.1,8.92,24.23,24.23,0,0,1-8.77,6.16,32.2,32.2,0,0,1-12.67,2.27,33.55,33.55,0,0,1-13-2.27,24.58,24.58,0,0,1-8.85-6.09,23,23,0,0,1-5-8.92A36.49,36.49,0,0,1,709,334.45v-62.3H727.1V291h38.51v13H727.1V332.6q0,6.66,2.48,10.27t8.14,3.61q5.52,0,8.14-3.54t2.62-10.05Z"/>
                                                <path className="cls-1"
                                                      d="M854.79,360.64a28.79,28.79,0,0,1-1.13-6.23,22.79,22.79,0,0,1-8.63,5.87,32.88,32.88,0,0,1-12.46,2.2q-12,0-17.63-5.31t-5.59-13.81q0-7.5,2.41-12a18,18,0,0,1,6.65-7,32.24,32.24,0,0,1,10.12-3.75q5.88-1.2,12.82-2.19t9.34-2.62c1.6-1.08,2.41-2.86,2.41-5.31a5.91,5.91,0,0,0-3.19-5.31,16.6,16.6,0,0,0-8.56-1.91c-4.25,0-7.29.94-9.14,2.83a13.26,13.26,0,0,0-3.46,7.5H811.62a31.43,31.43,0,0,1,1.77-9.91,19,19,0,0,1,5.23-7.78,24.56,24.56,0,0,1,9.42-5,49.07,49.07,0,0,1,14.16-1.77A46.22,46.22,0,0,1,856.14,291a22.51,22.51,0,0,1,9.2,5.31,20.3,20.3,0,0,1,5,8.64,40.37,40.37,0,0,1,1.56,11.68v44Zm-1.27-34.12a9.89,9.89,0,0,1-4.1,2.26,74.61,74.61,0,0,1-7.79,1.84q-7.93,1.56-10.9,4a8.4,8.4,0,0,0-3,6.93q0,7.8,8.92,7.79a17.83,17.83,0,0,0,6.59-1.2A16.08,16.08,0,0,0,852,340a13.33,13.33,0,0,0,1.34-5.88Z"/>
                                                <path className="cls-1"
                                                      d="M926.57,360.64V318.45q0-8.07-2.69-10.9t-9.2-2.84q-14.31,0-14.3,16v39.93H882V291h17.55v10.2a22.31,22.31,0,0,1,8.64-9q5.37-3,13.87-3.05a27.69,27.69,0,0,1,9.2,1.49,19.52,19.52,0,0,1,7.29,4.46,21.33,21.33,0,0,1,4.82,7.29,26.18,26.18,0,0,1,1.77,10v48.28Z"/>
                                                <path className="cls-1"
                                                      d="M1003.44,360.64l-.14-8.5q-6.37,10.33-19.39,10.34a28.69,28.69,0,0,1-12.18-2.55,26.57,26.57,0,0,1-9.48-7.36A35.61,35.61,0,0,1,956,340.82a50.59,50.59,0,0,1-2.27-15.72,52.1,52.1,0,0,1,1.91-14.51,34.49,34.49,0,0,1,5.59-11.4,25.54,25.54,0,0,1,9-7.43,27.12,27.12,0,0,1,12.11-2.62q12.89,0,20.67,10.9V265.5h18.12v95.14Zm-15.29-13.17A13.76,13.76,0,0,0,999.27,342q4.46-5.52,4.46-14.44,0-22.65-15.44-22.65-15.85,0-15.85,21.52,0,9.33,4.39,15.21A13.52,13.52,0,0,0,988.15,347.47Z"/>
                                                <path className="cls-1"
                                                      d="M62.72,504.64l-11-40.49-11,40.49H21.81L2,435H21.38l11.33,44.74L42.33,435H61l10.9,44.74L82.54,435h19l-20,69.66Z"/>
                                                <path className="cls-1"
                                                      d="M120.34,474.62q.42,7.94,4.6,12.6a14,14,0,0,0,11,4.67,15.39,15.39,0,0,0,8-2.05,9.31,9.31,0,0,0,4.46-5.73h19q-3.26,10.75-11.33,16.56a32.08,32.08,0,0,1-19.25,5.81q-34.83,0-34.83-37.94A44.27,44.27,0,0,1,104.2,454a31.41,31.41,0,0,1,6.58-11.18,28.42,28.42,0,0,1,10.55-7.15,38.5,38.5,0,0,1,14.3-2.48q16.14,0,24.42,10.34t8.28,31.14Zm29-11.61a19.3,19.3,0,0,0-1.34-6.72,14.19,14.19,0,0,0-3.19-4.81,12.66,12.66,0,0,0-4.53-2.84,15.42,15.42,0,0,0-5.24-.92,13.05,13.05,0,0,0-9.55,4q-4,4-4.61,11.25Z"/>
                                                <path className="cls-1"
                                                      d="M176.68,504.64V409.5H194.8V444q7.78-10.89,20.67-10.9a27.12,27.12,0,0,1,12.11,2.62,25.45,25.45,0,0,1,9,7.43,34.49,34.49,0,0,1,5.59,11.4,52.1,52.1,0,0,1,1.91,14.51,50.59,50.59,0,0,1-2.27,15.72,35.78,35.78,0,0,1-6.22,11.75,26.5,26.5,0,0,1-9.56,7.36,28.82,28.82,0,0,1-12.1,2.55q-13,0-19.4-10.34l-.14,8.5Zm33-13.17A13.52,13.52,0,0,0,221,485.59q4.39-5.86,4.39-15.21,0-21.53-15.85-21.52-15.43,0-15.43,22.65,0,8.91,4.46,14.44A13.72,13.72,0,0,0,209.67,491.47Z"/>
                                                <path className="cls-1"
                                                      d="M337.22,504.64l-.14-8.5q-6.37,10.33-19.4,10.34a28.68,28.68,0,0,1-12.17-2.55,26.69,26.69,0,0,1-9.49-7.36,35.8,35.8,0,0,1-6.23-11.75,50.6,50.6,0,0,1-2.26-15.72,52.1,52.1,0,0,1,1.91-14.51,34.49,34.49,0,0,1,5.59-11.4,25.45,25.45,0,0,1,9-7.43,27.12,27.12,0,0,1,12.11-2.62q12.87,0,20.67,10.9V409.5h18.12v95.14Zm-15.29-13.17A13.72,13.72,0,0,0,333,486q4.47-5.52,4.46-14.44,0-22.65-15.43-22.65-15.85,0-15.85,21.52,0,9.33,4.39,15.21A13.52,13.52,0,0,0,321.93,491.47Z"/>
                                                <path className="cls-1"
                                                      d="M381.81,474.62q.44,7.94,4.61,12.6a14,14,0,0,0,11,4.67,15.41,15.41,0,0,0,8-2.05,9.34,9.34,0,0,0,4.46-5.73h19q-3.27,10.75-11.33,16.56a32.1,32.1,0,0,1-19.25,5.81q-34.83,0-34.83-37.94A44.27,44.27,0,0,1,365.68,454a31.26,31.26,0,0,1,6.58-11.18,28.34,28.34,0,0,1,10.55-7.15,38.45,38.45,0,0,1,14.29-2.48q16.14,0,24.43,10.34t8.28,31.14Zm29-11.61a19.52,19.52,0,0,0-1.35-6.72,14,14,0,0,0-3.18-4.81,12.75,12.75,0,0,0-4.53-2.84,15.42,15.42,0,0,0-5.24-.92,13.08,13.08,0,0,0-9.56,4q-4,4-4.6,11.25Z"/>
                                                <path className="cls-1"
                                                      d="M455,504.64,430.09,435h20.53l14.58,46,14.72-46h19.4l-25.06,69.66Z"/>
                                                <path className="cls-1"
                                                      d="M518.29,474.62q.42,7.94,4.6,12.6a14,14,0,0,0,11,4.67,15.41,15.41,0,0,0,8-2.05,9.31,9.31,0,0,0,4.46-5.73h19Q562,494.86,554,500.67a32.1,32.1,0,0,1-19.25,5.81q-34.83,0-34.83-37.94A44.27,44.27,0,0,1,502.15,454a31.41,31.41,0,0,1,6.58-11.18,28.34,28.34,0,0,1,10.55-7.15,38.5,38.5,0,0,1,14.3-2.48q16.14,0,24.42,10.34t8.28,31.14Zm29-11.61a19.29,19.29,0,0,0-1.35-6.72,14,14,0,0,0-3.18-4.81,12.66,12.66,0,0,0-4.53-2.84,15.42,15.42,0,0,0-5.24-.92,13,13,0,0,0-9.55,4q-4.05,4-4.61,11.25Z"/>
                                                <path className="cls-1" d="M574.63,504.64V409.5H593v95.14Z"/>
                                                <path className="cls-1"
                                                      d="M637.06,506.48a40.15,40.15,0,0,1-15.14-2.69,31.08,31.08,0,0,1-18.27-19.12,44.32,44.32,0,0,1-2.4-14.86,43.6,43.6,0,0,1,2.47-15,31.39,31.39,0,0,1,18.41-19,40.23,40.23,0,0,1,14.93-2.62,39.3,39.3,0,0,1,14.73,2.62A31.1,31.1,0,0,1,670,454.66a44,44,0,0,1,2.48,15.15A43.4,43.4,0,0,1,670,484.89a32.8,32.8,0,0,1-7.08,11.53,31.24,31.24,0,0,1-11.18,7.44A39.49,39.49,0,0,1,637.06,506.48Zm0-15.44q16.85,0,16.85-21.23t-16.85-21.1q-16.84,0-16.84,21.1T637.06,491Z"/>
                                                <path className="cls-1"
                                                      d="M681.09,537.62V435h17.7l.14,8.5q6.38-10.34,19.39-10.34a28.86,28.86,0,0,1,12.11,2.55,26.38,26.38,0,0,1,9.55,7.36,35.61,35.61,0,0,1,6.23,11.75,50.59,50.59,0,0,1,2.27,15.72A52.1,52.1,0,0,1,746.57,485,34.34,34.34,0,0,1,741,496.42a25.37,25.37,0,0,1-9,7.44,27.12,27.12,0,0,1-12.11,2.62q-12.89,0-20.67-10.9v42Zm32.85-46.86q15.84,0,15.85-21.52,0-9.35-4.39-15.22a13.54,13.54,0,0,0-11.32-5.87A13.76,13.76,0,0,0,703,453.67q-4.46,5.52-4.46,14.44Q698.5,490.76,713.94,490.76Z"/>
                                                <path className="cls-1"
                                                      d="M774,474.62q.42,7.94,4.6,12.6a14,14,0,0,0,11,4.67,15.39,15.39,0,0,0,8-2.05,9.31,9.31,0,0,0,4.46-5.73h19q-3.26,10.75-11.32,16.56a32.11,32.11,0,0,1-19.26,5.81q-34.82,0-34.82-37.94A44.28,44.28,0,0,1,757.82,454a31.41,31.41,0,0,1,6.58-11.18A28.42,28.42,0,0,1,775,435.62a38.5,38.5,0,0,1,14.3-2.48q16.14,0,24.42,10.34T822,474.62ZM803,463a19.3,19.3,0,0,0-1.34-6.72,14,14,0,0,0-3.19-4.81,12.66,12.66,0,0,0-4.53-2.84,15.38,15.38,0,0,0-5.24-.92,13.05,13.05,0,0,0-9.55,4q-4,4-4.6,11.25Z"/>
                                                <path className="cls-1"
                                                      d="M830.3,504.64V435h17.28v8.36a22.59,22.59,0,0,1,4.74-5.53,20.52,20.52,0,0,1,5.1-3,19.39,19.39,0,0,1,5.3-1.34,52.6,52.6,0,0,1,5.53-.29h2.4V452a31.33,31.33,0,0,0-5.1-.42q-16.84,0-16.84,16.84v36.25Z"/>
                                            </g>
                                        </g>
                                    </svg>
                                    <span className='btm-text fade'>Machine Learning / Data Visualisation / M.E.R.N Stack</span>
                                    <div>
                                        <Button variant="outlined" onClick={this.handleClick}
                                                className='contact-button fade'>CONTACT
                                            ME</Button>
                                        <Button variant="outlined" onClick={() => {
                                            window.open('/documents/Justus Soh Zhi Heng_Resume.pdf')
                                        }}
                                                className='contact-button fade' style={{marginLeft:15, width:140}}>VIEW RESUME</Button>
                                    </div>
                                </Col>
                                <MediaQuery minWidth={426}>
                                    <Col sm={{span: 6, offset: 0}} className='d-flex align-items-center'>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 647.81 1059.14" id="j">
                                            <g id="Layer_2" data-name="Layer 2">
                                                <g id="Layer_1-2" data-name="Layer 1">
                                                    <path className="cls-2"
                                                          d="M430.09,665.76,100.14,94.27l-56,32.36,330,571.48c25.43,44.07,37.76,79.45,36.63,105.17s-20.48,49.47-57.56,70.87l56-32.35c37.08-21.41,56.45-45.25,57.56-70.87S455.53,709.82,430.09,665.76Z"/>
                                                    <path className="cls-3"
                                                          d="M263.07,1.37,601.6,587.73c42.1,72.91,54.88,144.9,38,214S571.74,930.24,488.11,978.52C413,1021.89,341.63,1035.19,276,1018S153,950.36,105.31,867.8L58.06,786l146.42-84.54,42.36,73.37c23.7,41.05,48.61,67.77,74,79.41s55.33,7.8,88.82-11.53c37.38-21.58,56.91-45.7,58-71.67s-11.23-61.48-36.77-105.72L101.48,94.66Z"/>
                                                    <path className="cls-2"
                                                          d="M275.79,1019c-32.94-8.6-64.06-25.66-92.49-50.68s-54.94-58.66-78.83-100.05L56.72,785.57l-56,32.36,47.75,82.7c23.9,41.39,50.42,75.06,78.83,100.06s59.55,42.07,92.5,50.67c65.87,17.2,137.47,3.87,212.81-39.62l56-32.36C413.26,1022.88,341.67,1036.21,275.79,1019Z"/>
                                                </g>
                                            </g>
                                        </svg>
                                    </Col>
                                </MediaQuery>
                                <Snackbar anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                                          open={this.state.mobile && !this.state.isLoading}
                                          message={<span id="message-id">View Desktop for full experience!</span>}
                                          action={
                                              <IconButton
                                                  key="close"
                                                  color="inherit"
                                                  onClick={this.handleSnackBarClose}
                                              >
                                                  <CloseIcon/>
                                              </IconButton>
                                          }
                                          TransitionComponent={this.SlideTransition}
                                />
                            </Row>
                        </Styles>
                    </Container>
                );
        }
    };

    render() {
        return (
            <SwitchTransition>
                <CSSTransition key={this.state.isLoading}
                               timeout={600}
                               classNames='page'
                >
                    {this.renderContent()}
                </CSSTransition>
            </SwitchTransition>
        )
    }
}

export default Home;

